import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../components/elements/button';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { Link } from 'gatsby-plugin-react-intl';
import Logo from '../components/logo';
import Navbar from '../components/navbar';
import { NNU } from '../helpers';
import SEO from '../components/seo';
import SideBar from '../components/sideBar';

export default function NotFoundPage() {
  const data = useStaticQuery(graphql`
    query imgErrorPage {
      file(relativePath: { eq: "404.png" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 700, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Layout
      navBar={layoutScrolledDown => (
        <Navbar scrolled={layoutScrolledDown}>
          <p> </p>
        </Navbar>
      )}
      sideBar={() => (
        <SideBar className='sidebar-secondary-page'>
          <Link to='/'>
            <Logo />
          </Link>
        </SideBar>
      )}
    >
      <SEO title='404: Not found' />
      <div className='flex flex-col items-center justify-center mt-12'>
        <GatsbyImage alt='' image={NNU(data?.file?.childImageSharp?.gatsbyImageData)} className='w-2/4 m-auto' />
        <h1 className='text-5xl capitalize '>Oops...</h1>
        <p>We can&apos;t seem to find the page you are looking for.</p>
        <Link to='/'>
          <Button className='transition duration-500 ease-in-out transform button-home z-2 hover:-translate-y-1 hover:scale-100 px-12 py-4'>Go Back</Button>
        </Link>
      </div>
    </Layout>
  );
}
