import * as React from 'react';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import { TCN } from '../helpers';

import './sidebar.scss';

export default function SideBar({ className, children }: { className?: string; children: React.ReactNode }) {
  const intl = useIntl();
  return (
    <div className={TCN('sidebar', className, className !== undefined)}>
      {children}
      <input className='checkbox none' type='checkbox' id='multilangue' checked={intl.locale === 'en'} onChange={e => changeLocale(e.target.checked ? 'en' : 'fr')} />
      <label htmlFor='multilangue' className='multilangue' />
    </div>
  );
}
